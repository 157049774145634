import React, { Fragment } from "react"

import StickyResponsiveSidebar from "./sidebar/sticky-responsive-sidebar"
import presets from "../utils/presets"
import typography, { rhythm } from "../utils/typography"
import StickTitleHeader from "./stick-title-header"
import Footer from "./footer"

export default props => {
  if (props.disable) {
    return props.renderContent()
  } else {
    const isRight = props.positionAlign === 'right' ? true : false
    return (
      <Fragment>
        <div
          css={{
            [presets.Tablet]: { paddingLeft: rhythm(10) },
            [`${presets.Tablet} and (max-width:980px)`]: {
              ".gatsby-highlight": {
                marginLeft: 0,
                marginRight: 0,
              },
            },
            [presets.Desktop]: { paddingLeft: rhythm(12) },
          }}
        >
          <>

            { isRight ? null : <StickTitleHeader title={props.contentTitle} /> }

            { isRight ? null :
              <div css={[{
                [presets.Tablet]: { paddingRight: props.hasRightSidebar ? rhythm(10) : 0 },
                [presets.Desktop]: { paddingRight: props.hasRightSidebar ? rhythm(12) : 0 },
              }]}>
                {props.renderContent()}
              </div>
            }

            { props.location.pathname === `/` || isRight ? null :
              <div id="pageFooter" css={[styles.footer, props.hasRightSidebar ? {
                position: 'relative',
                [presets.Tablet]: {
                  width: `calc(100% - ${rhythm(10)})`,
                  position: 'absolute',
                  zIndex: 105,
                },
                [presets.Desktop]: { width: `calc(100% - ${rhythm(12)})` },
              } : {} ]}>
                <Footer hasRightSidebar={props.hasRightSidebar} />
              </div>
            }
          </>
        </div>
        <StickyResponsiveSidebar
          positionAlign={props.positionAlign}
          enableScrollSync={props.enableScrollSync}
          itemList={props.itemList}
          key={props.location.pathname}
          location={props.location}
        />
      </Fragment>
    )
  }
}

const styles = {
  footer: {
    background: `dimGray`,
    fontFamily: typography.options.headerFontFamily.join(`,`),
    paddingTop: 0,
  },
}
