import React from "react"
import Card from "./card"
import { scale, rhythm } from "../utils/typography"
import presets, { colors } from "../utils/presets"

const StickTitleHeader = ({ title }) => {
  return (
    <div css={{ ...styles.contentHeader, ...styles.sticky }}>
        <h2 css={styles.contentTitle}>
            <span>
                {title}
            </span>
        </h2>
    </div>
  )
}

const styles = {
  contentHeader: {
      alignItems: `center`,
      background: `rgba(255,255,255,0.98)`,
      borderBottom: `1px solid ${colors.ui.light}`,
      display: `flex`,
      flexDirection: `row`,
      height: presets.headerHeight,
      paddingLeft: `${rhythm(3 / 4)}`,
      paddingRight: `${rhythm(3 / 4)}`,
      zIndex: 110,
  },
  contentTitle: {
      color: colors.skyDark,
      lineHeight: 1.2,
      margin: 0,
      [presets.Tablet]: {
        fontSize: scale(4 / 5).fontSize,
      },
      [presets.Desktop]: {
          fontSize: scale(9 / 10).fontSize,
      },
      [presets.VHd]: {
          fontSize: scale(1).fontSize,
      },
  },
  sticky: {
    position: `sticky`,
    top: `calc(${presets.bannerHeight} - 1px)`,
    [presets.Desktop]: {
      top: `calc(${presets.headerHeight} + ${presets.bannerHeight} - 1px)`,
    },
  },
}

export default StickTitleHeader
