import React from "react"
import { Link } from "gatsby"
import presets, { colors } from "../utils/presets"
import typography, { rhythm, scale, options } from "../utils/typography"
import IconFont from "./iconfont"

const getNavItemStyles = ({ isPartiallyCurrent }) =>
    isPartiallyCurrent ? { style: styles.link.active } : {}

const MobileNavItem = ({ linkTo, label, icon }) => (
    <Link to={linkTo} css={styles.link} getProps={getNavItemStyles}>
        <div>{icon}<div>{label}</div></div>
    </Link>
)

const MobileNavigation = () => {
    return (
    <React.Fragment>
        <div css={{
            // background: colors.ui.whisper,
            background: '#f9fcfe',
            borderTop: `1px solid #e7eff5`,
            display: `flex`,
            justifyContent: `space-around`,
            bottom: 0,
            left: 0,
            position: `fixed`,
            right: 0,
            zIndex: 200,
            minHeight: 56,
            fontFamily: typography.options.headerFontFamily.join(`,`),
            paddingBottom: `env(safe-area-inset-bottom)`,
            boxShadow: '4px 0px 8px 0 rgba(40,43,49,0.08)',
            "@media (min-width: 693px)": {
                display: `none`,
            },
        }}>
            <MobileNavItem linkTo="/" label="首页" icon={<IconFont type="icon-baojia" css={styles.navIcon} />} />
            <MobileNavItem linkTo="/technology/" label="技术介绍" icon={<IconFont type="icon-baojia" css={styles.navIcon} />} />
            <MobileNavItem linkTo="/case/" label="解决方案" icon={<IconFont type="icon-stars" css={styles.navIcon} />} />
            <MobileNavItem linkTo="/download/" label="APP下载" icon={<IconFont type="icon-ruanjianxiazai" css={styles.navIcon} />} />
            <MobileNavItem linkTo="/product/" label="路由器" icon={<IconFont  type="icon-chanpin" css={styles.navIcon} />} />
            <MobileNavItem linkTo="/about/" label="公司"  icon={<IconFont type="icon-stars" css={styles.navIcon} />} />
            {/* <MobileNavItem linkTo="/about/" label="关于华域安网" /> */}
        </div>
    </React.Fragment>
)}

export default MobileNavigation

const styles = {
    link: {
        color: 'rgb(87, 169, 235)',
        borderBottom: `0.15rem solid transparent`,
        borderRadius: presets.radius,
        fontSize: scale(-1 / 2).fontSize,
        flexShrink: 0,
        lineHeight: 1,
        width: 64,
        padding: `${rhythm(options.blockMarginBottom / 4)} ${rhythm(
            options.blockMarginBottom / 4
        )} 0`,
        textDecoration: `none`,
        textAlign: `center`,
        cursor: 'pointer',
        "&:hover": {
            color: `#1890FF`,
          },
          active: {
            // borderBottomColor: colors.skyDark,
            color: colors.skyDark,
            fontWeight: 'bold'
          },
    },
    navIcon: {
      display: 'block',
      height: 30,
      margin: '0 auto',
      fontSize: 18,
      paddingTop: 4,
    }
}
