import React, { Fragment } from "react"
import presets, { colors } from "../../utils/presets"
import { scale, options, rhythm } from "../../utils/typography"

const ExpandAllButton = ({ onClick, expandAll, overrideCSS = {} }) => (
  <button
    onClick={onClick}
    css={{
      ...scale(-2 / 3),
      lineHeight: 1,
      background: `transparent`,
      border: `none`,
      borderRadius: presets.radius,
      color: colors.skyDark,
      display: `flex`,
      cursor: `pointer`,
      alignItems: `center`,
      flexGrow: 0,
      marginLeft: `auto`,
      paddingTop: rhythm(options.blockMarginBottom / 3),
      paddingBottom: rhythm(options.blockMarginBottom / 3),
      fontFamily: options.systemFontFamily.join(`,`),
      textAlign: `left`,
      transition: `all .2s`,
      "&:hover": {
        color: `#1890FF`,
      },
      ...overrideCSS
    }}
  >
    {expandAll ? (
      <Fragment>
        <span>全部收起</span>
      </Fragment>
    ) : (
      <Fragment>
        <span>全部展开</span>
      </Fragment>
    )}
  </button>
)

export default ExpandAllButton
